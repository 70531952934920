import React, { Component } from "react";
import "./send_message.css";
import emailjs from "emailjs-com";
import ReactLoading from "react-loading";

export class SendMessage extends Component {
  constructor() {
    super();
    this.state = {
      message: "",
      user_name: "",
      user_email: "",
      error: false,
      loading: false,
      letter: true,
    };
  }

  myChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value, error: false });
  };

  sendEmail = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    if (
      this.state.message === "" ||
      this.state.user_name === "" ||
      this.state.user_email === ""
    ) {
      this.setState({ error: true, loading: false });
    } else {
      emailjs
        .send(
          process.env.REACT_APP_SERVICE_CODE,
          process.env.REACT_APP_TEMPLATE_ID,
          {
            message: this.state.message,
            user_name: this.state.user_name,
            user_email: this.state.user_email,
          },
          process.env.REACT_APP_USER_ID
        )
        .then(
          (result) => {
            if (result.text === "OK") {
              this.setState({
                loading: false,
                letter: false,
              });
            }
          },
          (error) => {
            this.setState({
              loading: false,
            });
          }
        );
    }
  };

  render() {
    return (
      <div
        className={"default-main " + (this.props.dark && "default-main-dark ")}
      >
        {this.state.letter ? (
          <form id="messageForm" className="letter" onSubmit={this.sendEmail}>
            <p
              className="error-message"
              style={this.state.error ? { opacity: "1" } : { opacity: "0" }}
            >
              Fields Cannot be Empty
            </p>
            <div
              className={
                "textarea-wrapper " +
                (this.props.dark && "textarea-wrapper-dark ")
              }
            >
              <p>
                <textarea
                  className={
                    "sm_textarea " + (this.props.dark && "sm_textarea-dark")
                  }
                  placeholder="Your message (may be feedback for this website!)"
                  maxLength="250"
                  name="message"
                  value={this.state.message}
                  onChange={this.myChangeHandler}
                ></textarea>
              </p>
            </div>
            <div
              className={
                "input-button-wrapper " +
                (this.props.dark && "input-button-wrapper-dark")
              }
            >
              <p>
                <input
                  className={
                    "sm_input " + (this.props.dark && "sm_input-dark ")
                  }
                  type="text"
                  placeholder="Your name"
                  name="user_name"
                  value={this.state.user_name}
                  onChange={this.myChangeHandler}
                />
              </p>
              <p>
                <input
                  className={
                    "sm_input " + (this.props.dark && "sm_input-dark ")
                  }
                  type="email"
                  placeholder="Your email"
                  name="user_email"
                  value={this.state.user_email}
                  onChange={this.myChangeHandler}
                />
              </p>
              <p>
                <button
                  className="sm_button border-button-effect"
                  onClick={() => {
                    this.state.error || this.state.loading
                      ? this.props.playE()
                      : this.props.playC();
                  }}
                  style={
                    this.state.loading
                      ? {
                          cursor: "not-allowed",
                        }
                      : {}
                  }
                  disabled={this.state.loading}
                >
                  {!this.state.loading ? (
                    "Send"
                  ) : (
                    <ReactLoading
                      type="spokes"
                      color="#494949"
                      height="24px"
                      width="24px"
                    />
                  )}
                </button>
              </p>
            </div>
          </form>
        ) : (
          <p className="result-message">Thank you for your message</p>
        )}
      </div>
    );
  }
}

export default SendMessage;

export const displaySendMessage = ({ dark, playC, playE }) => {
  return <SendMessage dark={dark} playC={playC} playE={playE}></SendMessage>;
};
