import React, { Component } from "react";
import "./pravinewa.css";
import TypeWriter from "typewriter-effect";
import Projects from "../project/projects";

export class AboutPrabin extends Component {
  constructor() {
    super();
    this.screens = {};
    this.state = {
      screen: () => {},
      active_screen: "about",
    };
  }
  componentDidMount() {
    this.screens = {
      about: <About playC={this.playC} />,
      education: <Education playC={this.playC} />,
      skills: <Skills />,
      projects: <Projects dark={this.props.dark} playC={this.props.playC} />,
      resume: <Resume />,
    };

    this.changeScreen(document.getElementById("about"));
  }

  playC = () => {
    this.props.playC();
  };

  changeScreen = (e) => {
    const screen = e.id || e.target.id;
    this.setState({
      screen: this.screens[screen],
      active_screen: screen,
    });
  };

  render() {
    return (
      <div className="about-main">
        <div className="nav-bar-about">
          <div
            id="about"
            tabIndex="0"
            onFocus={(e) => {
              this.playC();
              this.changeScreen(e);
            }}
            className={
              "nav-bar-about-item " +
              (this.props.dark && "nav-bar-about-item-dark ")
            }
            style={
              this.state.active_screen === "about"
                ? {
                    backgroundColor: this.props.dark
                      ? "rgba(255,255,255,0.1)"
                      : "navy",
                    color: "white",
                  }
                : {}
            }
          >
            Me
          </div>
          <div
            id="education"
            tabIndex="0"
            onFocus={(e) => {
              this.playC();
              this.changeScreen(e);
            }}
            className={
              "nav-bar-about-item " +
              (this.props.dark && "nav-bar-about-item-dark ")
            }
            style={
              this.state.active_screen === "education"
                ? {
                    backgroundColor: this.props.dark
                      ? "rgba(255,255,255,0.1)"
                      : "navy",
                    color: "white",
                  }
                : {}
            }
          >
            Education
          </div>
          <div
            id="skills"
            tabIndex="0"
            onFocus={(e) => {
              this.playC();
              this.changeScreen(e);
            }}
            className={
              "nav-bar-about-item " +
              (this.props.dark && "nav-bar-about-item-dark ")
            }
            style={
              this.state.active_screen === "skills"
                ? {
                    backgroundColor: this.props.dark
                      ? "rgba(255,255,255,0.1)"
                      : "navy",
                    color: "white",
                  }
                : {}
            }
          >
            Skills
          </div>
          <div
            id="projects"
            tabIndex="0"
            onFocus={(e) => {
              this.playC();
              this.changeScreen(e);
            }}
            className={
              "nav-bar-about-item " +
              (this.props.dark && "nav-bar-about-item-dark ")
            }
            style={
              this.state.active_screen === "projects"
                ? {
                    backgroundColor: this.props.dark
                      ? "rgba(255,255,255,0.1)"
                      : "navy",
                    color: "white",
                  }
                : {}
            }
          >
            Projects
          </div>
          <div
            id="resume"
            tabIndex="0"
            onFocus={(e) => {
              this.playC();
              this.changeScreen(e);
            }}
            className={
              "nav-bar-about-item " +
              (this.props.dark && "nav-bar-about-item-dark ")
            }
            style={
              this.state.active_screen === "resume"
                ? {
                    backgroundColor: this.props.dark
                      ? "rgba(255,255,255,0.1)"
                      : "navy",
                    color: "white",
                  }
                : {}
            }
          >
            Resume
          </div>
        </div>
        <div className="about-content ">
          <div
            className={
              "about-content-i " + (this.props.dark && "about-content-i-dark ")
            }
          >
            <div
              className={
                "about-content-ii " +
                (this.props.dark && "about-content-ii-dark")
              }
            >
              {this.state.screen}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutPrabin;

export const displayAboutPrabin = ({ dark, playC }) => {
  return <AboutPrabin dark={dark} playC={playC}></AboutPrabin>;
};

function About(props) {
  return (
    <div>
      <div className="about-me-logo">
        <img
          style={{ width: "100%", backgroundSize: "80px 80px" }}
          src="./images/profile/pravinewa.png"
          alt="Pravinewa logo"
        />
      </div>
      <div
        style={{
          paddingLeft: "6px",
          paddingRight: "6px",
          textAlign: "center",
          fontSize: "16px",
          lineHeight: "28px",
        }}
      >
        <div>
          My name is <span style={{ fontWeight: "700" }}>Prabin Shrestha</span>{" "}
          ,
        </div>
        <div
          style={{
            fontWeight: "500",
            width: "100%",
          }}
        >
          I'm a{" "}
          <span
            style={{
              marginLeft: "10px",
              fontWeight: "700",
              fontSize: "22px",
            }}
          >
            <TypeWriter
              onInit={(typewriter) => {
                typewriter
                  .typeString("Spring Boot Developer")
                  .pauseFor(1500)
                  .deleteAll()
                  .typeString("React Developer")
                  .pauseFor(1500)
                  .deleteAll()
                  .start();
              }}
              options={{ loop: true }}
            />
          </span>
        </div>
        <div className="typewriter-wrapper">
          <TypeWriter
            onInit={(typewriter) => {
              typewriter
                .typeString(
                  "➣\tUpcoming BESE graduate offering a strong foundation in software engineering and programming principles across multiple platforms.<br>"
                )
                .pauseFor(2000)
                .typeString(
                  "➣\tExperienced in object-oriented programming, developing, testing and debugging code, designing interfaces, and administering systems.<br>"
                )
                .pauseFor(2000)
                .typeString(
                  "➣\tQuickly learn and master new technologies, successful working in both team and self-directed settings."
                )
                .start();
            }}
            options={{ delay: "40" }}
          />
        </div>
      </div>
      <div className="social-footer">
        <div
          className="social-icon-div"
          style={{
            backgroundImage: `url("./images/social/github.png")`,
          }}
          onClick={() => {
            props.playC();
            window.open("https://github.com/whopravinewa");
          }}
        />
        <div
          className="social-icon-div"
          style={{
            backgroundImage: `url("./images/social/linkedin.png")`,
          }}
          onClick={() => {
            props.playC();
            window.open("https://www.linkedin.com/in/whopravinewa");
          }}
        />
        <div
          className="social-icon-div"
          style={{
            backgroundImage: `url("./images/social/twitter.png")`,
          }}
          onClick={() => {
            props.playC();
            window.open("https://twitter.com/whopravinewa");
          }}
        />
      </div>
    </div>
  );
}

function Education(props) {
  return (
    <div>
      <ul
        style={{
          marginTop: "48px",
          marginLeft: "16px",
        }}
      >
        <li className="list-arrow" style={{ marginTop: "20px" }}>
          <div
            className="college-heading"
            onClick={() => {
              props.playC();
              window.open("https://gces.edu.np/");
            }}
          >
            Gandaki College of Engineering and Science
          </div>
          <div className="college-date">(2016 - 2020)</div>
          <div className="college-course">Software Engineering</div>
        </li>
        <li className="list-arrow" style={{ marginTop: "20px" }}>
          <div
            className="college-heading"
            onClick={() => {
              props.playC();
              window.open("https://sagarmathapokhara.edu.np/");
            }}
          >
            Sagarmatha Higher Secondary School
          </div>
          <div className="college-date">(2014 - 2016)</div>
          <div className="college-course">Higher Secondary Education Board</div>
        </li>
        <li className="list-arrow" style={{ marginTop: "20px" }}>
          <div className="college-heading">
            Shree Shitaladevi Community Secondary School
          </div>
          <div className="college-date">(2004 - 2014)</div>
          <div className="college-course">School Leaving Certificate</div>
        </li>
      </ul>
    </div>
  );
}

function Skills() {
  return (
    <div>
      <ul style={{ marginTop: "48px", marginLeft: "16px" }}>
        <li
          className="list-arrow"
          style={{
            marginTop: "16px",
            fontSize: "16px",
            lineHeight: "20px",
          }}
        >
          I've worked with a wide variety of programming languages & frameworks.
        </li>
        <li
          className="list-arrow"
          style={{
            fontSize: "16px",
            lineHeight: "20px",
          }}
        >
          <div>Here are my most frequently used</div>
        </li>
      </ul>
      <div style={{ width: "100%", display: "flex", marginTop: "32px" }}>
        <div className="skill-title">Languages & Tools</div>
        <div className="skill-title">Frameworks & Libraries</div>
      </div>
      <div className="skill-wrapper">
        <div style={{ width: "50%", paddingLeft: "8px", paddingRight: "8px" }}>
          <div className="skill-tag-wrapper">
            <img
              className="img-tag"
              src="https://img.shields.io/badge/Java-ED8B00?style=flat&logo=java&logoColor=white"
              alt="java"
            />
            <img
              className="img-tag"
              src="https://img.shields.io/badge/JavaScript-F7DF1E?style=flat&logo=javascript&logoColor=black"
              alt="javascript"
            />
            <img
              className="img-tag"
              src="https://img.shields.io/badge/Dart-0175C2?style=flat&logo=dart&logoColor=white"
              alt="dart"
            />
            <img
              src="https://img.shields.io/badge/-Git-%23F05032?style=flat&logo=git&logoColor=%23ffffff"
              alt="git"
              className="img-tag"
            />
            <img
              src="	https://img.shields.io/badge/MySQL-00000F?style=flat&logo=mysql&logoColor=white"
              alt="mysql"
              className="img-tag"
            />
            <img
              src="https://img.shields.io/badge/-Firebase-FFCA28?style=flat&logo=firebase&logoColor=ffffff"
              alt="firebase"
              className="img-tag"
            />
          </div>
        </div>
        <div
          style={{
            width: "50%",
            paddingLeft: "8px",
            paddingRight: "8px",
          }}
        >
          <div className="skill-tag-wrapper">
            <img
              className="img-tag"
              src="https://img.shields.io/badge/Spring-6DB33F?style=flat&logo=spring&logoColor=white"
              alt="spring"
            />
            <img
              className="img-tag"
              src="https://img.shields.io/badge/React-20232A?style=flat&logo=react&logoColor=61DAFB"
              alt="react"
            />
            <img
              className="img-tag"
              src="https://img.shields.io/badge/Flutter-02569B?style=flat&logo=flutter&logoColor=white"
              alt="flutter"
            />
            <img
              src="https://img.shields.io/badge/Node.js-43853D?style=flat&logo=node.js&logoColor=white"
              alt="node.js"
              className="img-tag"
            />
            <img
              className="img-tag"
              src="https://img.shields.io/badge/Redux-593D88?style=flat&logo=redux&logoColor=white"
              alt="redux"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Resume() {
  return (
    <iframe
      style={{ width: "100%", height: "100%" }}
      src="./images/files/resume.pdf"
      title="resume"
      frameBorder="0"
    ></iframe>
  );
}
