import React from "react";
import Windows from "./component/windows";
import Restrict from "./component/restrict";
import { useMediaQuery } from "react-responsive";
import "./App.css";

const App = () => {
  const isMaxMobileW = useMediaQuery({ query: `(max-width: 992px)` });
  const isMaxMobileH = useMediaQuery({ query: `(max-height: 400px)` });
  return isMaxMobileW || isMaxMobileH ? <Restrict /> : <Windows />;
};

export default App;
