import React from "react";
import "./desktop_menu.css";

const DesktopMenu = (props) => {
  let divider = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <div
          style={{
            borderTopWidth: "1px",
            borderColor: "gray",
            paddingTop: "4px",
            paddingBottom: "4px",
            width: "98%",
          }}
        ></div>
      </div>
    );
  };

  let openSettings = () => {
    props.playC();
    props.openApp("settings");
  };

  let refreshDesktop = () => {
    props.playC();
    props.refresh();
  };

  return (
    <div
      id="desktop-menu"
      className={
        props.active
          ? "desktop-menu desktop-menu-active " +
            (props.dark && "desktop-menu-dark ")
          : "desktop-menu desktop-menu-inactive"
      }
    >
      <div
        className={
          "desktop-menu-item " + (props.dark && "desktop-menu-item-dark ")
        }
      >
        <span onClick={refreshDesktop} style={{ marginLeft: "16px" }}>
          Refresh
        </span>
      </div>
      {divider()}
      <div
        className={
          "desktop-menu-item desktop-menu-item-inactive " +
          (props.dark && "desktop-menu-item-dark ")
        }
      >
        <span style={{ marginLeft: "16px" }}>Copy</span>
      </div>
      <div
        className={
          "desktop-menu-item desktop-menu-item-inactive " +
          (props.dark && "desktop-menu-item-dark ")
        }
      >
        <span style={{ marginLeft: "16px" }}>Paste</span>
      </div>
      <div
        className={
          "desktop-menu-item desktop-menu-item-inactive " +
          (props.dark && "desktop-menu-item-dark ")
        }
      >
        <span style={{ marginLeft: "16px" }}>Undo Move</span>
      </div>
      {divider()}
      <div
        onClick={openSettings}
        className={
          "desktop-menu-item " + (props.dark && "desktop-menu-item-dark ")
        }
      >
        <span style={{ marginLeft: "16px" }}>Settings</span>
      </div>
      {divider()}
      <div
        onClick={() => {
          localStorage.clear();
          window.location.reload();
        }}
        className={
          "desktop-menu-item " + (props.dark && "desktop-menu-item-dark ")
        }
      >
        <span style={{ marginLeft: "16px" }}>Reset Windows</span>
      </div>
    </div>
  );
};

export default DesktopMenu;
