import React from "react";
import "./booting_screen.css";

const BootingScreen = ({ visible }) => {
  return (
    <div className="body">
      <div
        className="overlay-splash-screen"
        style={
          visible
            ? {
                zIndex: "100",
                backgroundImage: `url(
                  "/images/wallpapers/starting-screen.jpg"
                )`,
              }
            : { zIndex: "-20", display: "none" }
        }
      >
        <div
          className="loading-bar"
          style={
            visible
              ? {
                  zIndex: "100",
                  backgroundImage: `url("./images/icon/bar.png")`,
                }
              : { zIndex: "-20", display: "none" }
          }
        ></div>
      </div>
    </div>
  );
};

export default BootingScreen;
