import React, { Component } from "react";
import "./explorer.css";

export class Explorer extends Component {
  constructor() {
    super();
    this.home_url = "https://www.google.com/webhp?igu=1";
    this.state = {
      url: "https://www.google.com/webhp?igu=1",
      display_url: "https://www.google.com",
    };
  }

  refreshExplorer = () => {
    document.getElementById("explorer-screen").src += "";
  };

  goToHome = () => {
    this.setState({
      url: this.home_url,
      display_url: "https://www.google.com",
    });
    this.refreshExplorer();
  };

  checkKey = (e) => {
    if (e.key === "Enter") {
      let url = e.target.value;
      let display_url = "";

      url = url.trim();
      if (url.length === 0) return;

      if (url.indexOf("http://") !== 0 && url.indexOf("https://") !== 0) {
        url = "https://" + url;
      }

      url = encodeURI(url);
      display_url = url;
      if (url.includes("google.com")) {
        url = "https://www.google.com/webhp?igu=1";
        display_url = "https://www.google.com";
      }
      this.setState({ url, display_url: url });
      this.storeVisitedUrl(url, display_url);
      document.getElementById("explorer-url-bar").blur();
    }
  };

  handleDisplayUrl = (e) => {
    this.setState({ display_url: e.target.value });
  };

  displayUrlBar = () => {
    return (
      <div className="nav-bar-explorer">
        <div
          className="nav-bar-btn-explorer refresh-btn-explorer"
          style={{
            backgroundImage: this.props.dark
              ? `url(
              "./images/icon/window/explorer_refresh_white.svg"
            )`
              : `url(
              "./images/icon/window/explorer_refresh.svg"
            )`,
          }}
          onClick={this.refreshExplorer}
        ></div>
        <div
          className="nav-bar-btn-explorer home-btn-explorer"
          style={{
            backgroundImage: this.props.dark
              ? `url(
              "./images/icon/window/explorer_home_white.svg"
            )`
              : `url(
              "./images/icon/window/explorer_home.svg"
            )`,
          }}
          onClick={this.goToHome}
        ></div>
        <input
          className={
            "nav-bar-url-bar-explorer " +
            (this.props.dark && "nav-bar-url-bar-explorer-dark ")
          }
          onKeyDown={this.checkKey}
          onChange={this.handleDisplayUrl}
          value={this.state.display_url}
          id="explorer-url-bar"
          type="url"
          spellCheck={false}
          autoComplete="off"
        />
      </div>
    );
  };

  render() {
    return (
      <div
        className={
          "explorer-main " + (this.props.dark && "explorer-main-dark ")
        }
      >
        {this.displayUrlBar()}
        <iframe
          src={this.state.url}
          className="screen-explorer"
          id="explorer-screen"
          frameBorder="0"
          title="Explorer Url"
        ></iframe>
      </div>
    );
  }
}

export default Explorer;

export const displayExplorer = (dark) => {
  return <Explorer dark={dark}> </Explorer>;
};
