import React, { Component } from "react";
import BootingScreen from "./screen/boot-screen/booting_screen";
import Desktop from "./screen/desktop/desktop";

class Windows extends Component {
  constructor() {
    super();
    this.state = {
      bg_image_name: "wall-1",
      booting_screen: true,
    };
    this.audio = new Audio("./images/sound/startup_sound.mp3");
  }

  componentDidMount() {
    this.getLocalData();
  }

  playSound = () => {
    this.audio.play();
  };

  setTimeOutBootScreen = () => {
    this.playSound();
    setTimeout(() => {
      this.setState({ booting_screen: false });
      localStorage.setItem("booting_screen", false);
    }, 6000);
  };

  getLocalData = () => {
    let bg_image_name = localStorage.getItem("bg-image");
    if (bg_image_name !== null && bg_image_name !== undefined) {
      this.setState({ bg_image_name });
    }

    let booting_screen = localStorage.getItem("booting_screen");
    if (booting_screen !== null && booting_screen !== undefined) {
      this.setState({ booting_screen: false });
    } else {
      localStorage.setItem("booting_screen", false);
      this.setTimeOutBootScreen();
    }
  };

  changeBackgroundImage = (img_name) => {
    this.setState({ bg_image_name: img_name });
    localStorage.setItem("bg-image", img_name);
  };

  restart = () => {
    this.setState({ booting_screen: true });
    localStorage.setItem("booting_screen", true);
    this.setTimeOutBootScreen();
  };

  render() {
    return (
      <div style={{ width: "100vw", height: "100vh", overflow: "hidden" }}>
        <BootingScreen visible={this.state.booting_screen} />
        {!this.state.booting_screen && (
          <div style={{ width: "100vw", height: "100vh", overflow: "hidden" }}>
            <Desktop
              bg_image_name={this.state.bg_image_name}
              changeBackgroundImage={this.changeBackgroundImage}
              menu={this.state.menu}
              showMenu={this.showMenu}
              restart={this.restart}
            />
          </div>
        )}
      </div>
    );
  }
}

export default Windows;
