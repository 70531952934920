import React, { Component } from "react";

class Restrict extends Component {
  constructor() {
    super();
    this.bg_img = {
      "wall-1": "./images/wallpapers/restrict/default-green.jpg",
      "wall-2": "./images/wallpapers/restrict/default-blue-with-logo.jpg",
      "wall-3": "./images/wallpapers/restrict/sky-with-logo.jpg",
      "wall-4": "./images/wallpapers/restrict/blue-screen-of-death.jpg",
      "wall-5": "./images/wallpapers/restrict/green-with-logo.jpg",
      "wall-6": "./images/wallpapers/restrict/sky-with-logo-2.jpg",
      "wall-7": "./images/wallpapers/restrict/stonehenge.jpg",
      "wall-8": "./images/wallpapers/restrict/window-wallpaper.jpg",
      "wall-9": "./images/wallpapers/restrict/xp-wallpaper.jpg",
    };

    this.state = { bg_image_name: "wall-1" };
  }

  componentDidMount() {
    this.getLocalData();
  }

  getLocalData = () => {
    let bg_image_name = localStorage.getItem("bg-image");
    if (bg_image_name !== null && bg_image_name !== undefined) {
      this.setState({ bg_image_name });
    }
  };

  render() {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          backgroundImage: `url(${
            process.env.PUBLIC_URL + this.bg_img[this.state.bg_image_name]
          })`,
          backgroundSize: "100% 100%",
          backgroundPosition: "0px 0px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          style={{
            display: "block",
            width: "80%",
            height: "80%",
            overflow: "hidden",
            margin: "auto",
            marginTop: "50px",
            backgroundImage: `url(${
              process.env.PUBLIC_URL +
              "./images/wallpapers/restrict/screen-size.png"
            })`,
            backgroundSize: "contain",
            backgroundPosition: "50% 50%",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    );
  }
}

export default Restrict;
