import React, { useState, useEffect } from "react";
import "./start_bar.css";
import { formatAMPM } from "../../util/convertor/format_ampm";

const StartBar = ({
  menu,
  apps,
  openApp,
  playC,
  hasMinimised,
  minimized,
  app_stack,
  showMenu,
  focus,
  focused_windows,
  dark,
  audio,
  toggleAudio,
}) => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    var timerId = setInterval(() => tick(), 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  });

  useEffect(() => {
    renderOpenAppInBar();
  });

  function tick() {
    setDate(new Date());
  }

  const setWindowsPosition = (id) => {
    var r = document.querySelector("#" + id);
    var rect = r.getBoundingClientRect();
    r.style.setProperty(
      "--window-transform-x",
      rect.x.toFixed(1).toString() + "px"
    );
    r.style.setProperty(
      "--window-transform-y",
      rect.y.toFixed(1).toString() + "px"
    );
  };

  const minimizeWindow = (id) => {
    let posy = 510;

    setWindowsPosition(id);
    // get corrosponding sidebar app's position
    var r = document.querySelector("#startbar-" + id);
    var sidebBarApp = r.getBoundingClientRect();

    r = document.querySelector("#" + id);
    // translate window to that position
    r.style.transform = `translate(${
      sidebBarApp.x.toFixed(1) - 540
    }px,${posy}px) scale(0.2)`;

    hasMinimised(id);
  };

  const check_app_for_minimize = (id) => {
    playC();
    minimized[id]
      ? openApp(id)
      : focused_windows[id]
      ? minimizeWindow(id)
      : focus(id);
  };

  const renderOpenAppInBar = () => {
    let barJsx = [];
    app_stack.forEach((id) => {
      let app = apps.find((app) => app.id === id);
      barJsx.push(
        <div
          key={id}
          className={
            (dark ? "recent-app-wrapper-dark " : "recent-app-wrapper ") +
            (minimized[app.id]
              ? dark
                ? "border-button-effect-dark "
                : "border-button-effect "
              : dark
              ? "border-button-non-effect-dark "
              : "border-button-non-effect ") +
            (focused_windows[app.id]
              ? dark
                ? "recent-app-wrapper-focused-dark  "
                : "recent-app-wrapper-focused  "
              : " ")
          }
          id={"startbar-" + id}
          onClick={() => check_app_for_minimize(app.id)}
        >
          <div
            className="icon-div"
            style={{
              backgroundImage: `url(${app.icon})`,
              backgroundSize: "20px 20px",
            }}
          ></div>
          {focused_windows[app.id] && app.title}
        </div>
      );
    });

    return barJsx;
  };

  return (
    <div className={"start-bar " + (dark && "start-bar-dark ")}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          className={
            "start-button border-button-effect " +
            (dark && "start-button-dark border-button-effect-dark ")
          }
          style={{
            backgroundImage: dark
              ? `url("./images/icon/startmenu_dark.png")`
              : `url("./images/icon/startmenu.png")`,
            backgroundSize: "64px 20px",
          }}
          onClick={() => {
            playC();
            showMenu(!menu);
          }}
        ></div>
        {renderOpenAppInBar()}
      </div>
      <div className={"button-wrapper " + (dark && "button-wrapper-dark")}>
        <div
          className="icon-div"
          style={{
            backgroundImage: `url( "./images/icon/internet_connection.png"
            )`,
            backgroundSize: "20px 20px",
          }}
        />
        <div
          className="icon-div"
          style={{
            backgroundImage: `url( "./images/icon/calendar.png"
            )`,
            backgroundSize: "20px 20px",
          }}
        />
        <div
          className="icon-div"
          style={{
            backgroundImage: audio
              ? `url("./images/icon/loudspeaker.png")`
              : `url("./images/icon/loudspeaker_mute.png")`,
            backgroundSize: "20px 20px",
            cursor: "pointer",
          }}
          onClick={toggleAudio}
        />
        <div className={"time-div " + (dark && "time-div-dark ")}>
          {formatAMPM(date)}
        </div>
      </div>
    </div>
  );
};

export default StartBar;
