import React from "react";
import "./setting.css";
import Switch from "react-switch";

export function Settings(props) {
  const bg_img = {
    "wall-1": {
      value: "./images/wallpapers/default-green.png",
      title: "(None)",
    },
    "wall-2": {
      value: "./images/wallpapers/default-blue-with-logo.png",
      title: "Logo with Blue Background",
    },
    "wall-3": {
      value: "./images/wallpapers/sky-with-logo.jpg",
      title: "Brighter Windows 95",
    },
    "wall-4": {
      value: "./images/wallpapers/blue-screen-of-death.png",
      title: "Blue Screen",
    },
    "wall-5": {
      value: "./images/wallpapers/green-with-logo.jpg",
      title: "Logo with Green Background",
    },
    "wall-6": {
      value: "./images/wallpapers/sky-with-logo-2.jpg",
      title: "Sky with Logo Windows 95",
    },
    "wall-7": {
      value: "/images/wallpapers/stonehenge.jpg",
      title: "Stonehenge",
    },
    "wall-8": {
      value: "./images/wallpapers/window-wallpaper.jpg",
      title: "Dimmer Windows 95",
    },
    "wall-9": {
      value: "./images/wallpapers/xp-wallpaper.jpg",
      title: "Windows XP Wallpaper",
    },
  };

  const changeBackground = (val) => {
    props.playC();
    props.changeBackgroundImage(val);
  };

  const renderImageList = () => {
    return Object.keys(bg_img).map((obj, i) => {
      return (
        <li
          className={"wallpaper-list " + (props.dark && "wallpaper-list-dark ")}
          key={obj}
          onClick={() => changeBackground(obj)}
          style={
            obj === props.bg_image_name
              ? {
                  backgroundColor: props.dark
                    ? "#000000"
                    : "rgba(3, 0, 127, 255)",
                  color: "white",
                  cursor: "pointer",
                }
              : { cursor: "pointer" }
          }
        >
          {bg_img[obj].title}
        </li>
      );
    });
  };

  const returnImageValue = (val) => {
    if (Object.keys(bg_img).includes(val)) {
      return bg_img[val].value;
    }
  };

  const handleDarkChange = (val) => {
    props.toggleDarkMode(val);
  };

  return (
    <div className={"setting-main " + (props.dark && "setting-main-dark ")}>
      <div className="switch-btn">
        <Switch
          checked={props.dark ? true : false}
          onChange={handleDarkChange}
          onColor="#86d3ff"
          onHandleColor="#2693e6"
          handleDiameter={30}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={16}
          width={45}
          className="react-switch"
          id="material-switch"
        />
      </div>
      <div
        className="monitor-img"
        style={{
          backgroundImage: `url("./images/icon/monitor.png"
          )`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div
          className="monitor-img-content"
          style={{
            backgroundImage: `url(${returnImageValue(props.bg_image_name)})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></div>
      </div>
      <div className={"wallpaper-box " + (props.dark && "wallpaper-box-dark ")}>
        <p
          className={"wallpaper-text " + (props.dark && "wallpaper-text-dark ")}
        >
          Wallpaper
        </p>
        <div
          className={
            "wallpaper-box-with-heading " +
            (props.dark && "wallpaper-box-with-heading-dark ")
          }
        >
          {renderImageList()}
        </div>
      </div>
    </div>
  );
}

export default Settings;

export const displaySetting = () => {
  return <Settings> </Settings>;
};
