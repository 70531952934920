import { displaySetting } from "../apps/setting/setting";
import { displayExplorer } from "../apps/explorer/explorer";
import { displayAboutPrabin } from "../apps/pravinewa/pravinewa";
import { displayMineSweeper } from "../apps/minesweeper/minesweeper";
import { displayDefault } from "../apps/default/default";
import { displayMusic } from "../apps/music/music";
import { displaySendMessage } from "../apps/send_message/send_message";
import { displayProjects } from "../apps/project/projects";
import { displayTerminal } from "../apps/terminal/terminal";
import { displayComputer } from "../apps/computer/computer";

const apps = [
  {
    id: "computer",
    title: "My Computer",
    heading: "My Computer",
    icon: "./images/icon/my_computer.png",
    disabled: true,
    start_bar: false,
    resizable: true,
    menu_shortcut: false,
    desktop_shortcut: true,
    screen: displayComputer,
  },
  {
    id: "recycle",
    title: "Recycle Bin",
    heading: "Recycle Bin",
    icon: "./images/icon/recycle_bin.png",
    disabled: true,
    start_bar: false,
    resizable: true,
    menu_shortcut: false,
    desktop_shortcut: true,
    screen: displayDefault,
  },
  {
    id: "explorer",
    title: "Internet Explorer",
    heading: "Internet Explorer",
    icon: "./images/icon/internet_explorer.png",
    disabled: false,
    start_bar: false,
    resizable: true,
    menu_shortcut: false,
    desktop_shortcut: true,
    screen: displayExplorer,
  },
  {
    id: "projects",
    title: "Projects",
    heading: "Projects",
    icon: "./images/icon/appwizard.png",
    disabled: false,
    start_bar: false,
    resizable: true,
    menu_shortcut: true,
    desktop_shortcut: false,
    screen: displayProjects,
  },
  {
    id: "aboutme",
    title: "About Prabin",
    heading: "About Prabin",
    icon: "./images/icon/document.png",
    disabled: false,
    start_bar: false,
    resizable: true,
    menu_shortcut: false,
    desktop_shortcut: true,
    screen: displayAboutPrabin,
  },
  {
    id: "music",
    title: "Music",
    heading: "Music",
    icon: "./images/icon/music.png",
    disabled: false,
    start_bar: false,
    resizable: true,
    menu_shortcut: true,
    desktop_shortcut: false,
    screen: displayMusic,
  },
  {
    id: "settings",
    title: "Settings",
    heading: "Settings",
    icon: "./images/icon/settings.png",
    disabled: false,
    start_bar: false,
    resizable: false,
    menu_shortcut: true,
    desktop_shortcut: false,
    screen: displaySetting,
  },
  {
    id: "minesweeper",
    title: "Minesweeper",
    heading: "Minesweeper",
    icon: "./images/icon/minesweeper.png",
    disabled: false,
    start_bar: false,
    resizable: false,
    menu_shortcut: true,
    desktop_shortcut: false,
    screen: displayMineSweeper,
  },
  {
    id: "help",
    title: "Help",
    heading: "Help",
    icon: "./images/icon/help_book.png",
    disabled: true,
    start_bar: false,
    resizable: true,
    menu_shortcut: true,
    desktop_shortcut: false,
    screen: displayDefault,
  },
  {
    id: "terminal",
    title: "Terminal",
    heading: "C:\\Windows\\System32\\COMMAND.COM",
    icon: "./images/icon/application_hourglass.png",
    disabled: false,
    start_bar: false,
    resizable: true,
    menu_shortcut: true,
    desktop_shortcut: false,
    screen: displayTerminal,
  },
  {
    id: "message",
    title: "Send Message",
    heading: "Send Message",
    icon: "./images/icon/send_message.png",
    disabled: false,
    start_bar: false,
    resizable: false,
    menu_shortcut: false,
    desktop_shortcut: true,
    screen: displaySendMessage,
  },
];

export default apps;
