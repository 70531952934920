import React, { Component } from "react";
import Minesweeper from "react-minesweeper";
import "./minesweeper.css";

class MineSweeper extends Component {
  constructor() {
    super();
    this.state = {
      msg: "GAME IN PROGRESS",
      minesweeperKey: 0,
      bombChance: 15,
      width: 10,
      height: 10,
    };
  }

  onWin = () => {
    this.setState({
      msg: "YOU WON!",
    });
  };
  onLose = () => {
    this.setState({
      msg: "GAME OVER!",
    });
  };
  restartMinesweeper = () => {
    this.props.playC();
    this.setState((prevState) => ({
      minesweeperKey: prevState.minesweeperKey + 1,
      msg: "GAME IN PROGRESS",
    }));
  };
  updateStateProp = (prop) => (e) => {
    const value = Number(e.target.value);
    this.setState((prevState) => {
      prevState[prop] = value;
      prevState.minesweeperKey += 1;
      return prevState;
    });
  };
  render() {
    const { msg, minesweeperKey, bombChance, height, width } = this.state;

    return (
      <div className={"container " + (this.props.dark && "container-dark ")}>
        <div className="minesweeper__header">
          <button
            className="minesweeper__restart"
            style={{
              backgroundImage: `url("./images/icon/smile.png")`,
              backgroundSize: "22px 22px",
              cursor: "pointer",
            }}
            onClick={this.restartMinesweeper}
          ></button>
        </div>
        <Minesweeper
          className={
            "minesweeper-main " + (this.props.dark && "minesweeper-main-dark ")
          }
          key={minesweeperKey}
          onWin={this.onWin}
          onLose={this.onLose}
          bombChance={bombChance / 100}
          width={width}
          height={height}
        />
        <p style={{ textAlign: "center" }}>Status : {msg}</p>
      </div>
    );
  }
}
export default MineSweeper;

export const displayMineSweeper = ({ dark, playC }) => {
  return <MineSweeper dark={dark} playC={playC}></MineSweeper>;
};
