import React, { Component } from "react";
import Draggable from "react-draggable";
import { displayExplorer } from "../../apps/explorer/explorer";
import { displayMineSweeper } from "../../apps/minesweeper/minesweeper";
import { displayMusic } from "../../apps/music/music";
import { displayAboutPrabin } from "../../apps/pravinewa/pravinewa";
import { displayProjects } from "../../apps/project/projects";
import { displaySendMessage } from "../../apps/send_message/send_message";
import Settings from "../../apps/setting/setting";
import { displayTerminal } from "../../apps/terminal/terminal";
import "./window.css";

export class Window extends Component {
  constructor() {
    super();
    this.id = null;
    this.myRef = React.createRef(null); //to remove warning in console
    this.startX = 220;
    this.startY = 40;
    this.state = {
      cursorType: "default",
      width: 60,
      height: 85,
      closed: false,
      maximized: false,
      parentSize: {
        height: 100,
        width: 100,
      },
    };
  }

  componentDidMount() {
    this.id = this.props.id;
    this.setDefaultWindowDimenstion();
  }

  setDefaultWindowDimenstion = () => {
    if (window.innerWidth < 640) {
      this.setState({ height: 60, width: 85 }, this.resizeBoundries);
    } else {
      this.setState({ height: 85, width: 60 }, this.resizeBoundries);
    }
  };

  resizeBoundries = () => {
    this.setState({
      parentSize: {
        height:
          window.innerHeight - //parent height
          window.innerHeight * (this.state.height / 100.0) - // this window's height
          28, // some padding
        width:
          window.innerWidth - // parent width
          window.innerWidth * (this.state.width / 100.0), //this window's width
      },
    });
  };

  changeCursorToMove = () => {
    this.focusWindow();
    this.setState({ cursorType: "move" });
  };

  changeCursorToDefault = () => {
    this.setState({ cursorType: "default" });
  };

  setWindowsPosition = () => {
    var r = document.querySelector("#" + this.id);
    var rect = r.getBoundingClientRect();
    r.style.setProperty(
      "--window-transform-x",
      rect.x.toFixed(1).toString() + "px"
    );
    r.style.setProperty(
      "--window-transform-y",
      rect.y.toFixed(1).toString() + "px"
    );
  };

  minimizeWindow = () => {
    let posy = 510;
    this.props.playC();

    this.setWindowsPosition();
    // get corrosponding sidebar app's position
    var r = document.querySelector("#startbar-" + this.id);
    var startBarApp = r.getBoundingClientRect();

    r = document.querySelector("#" + this.id);
    // translate window to that position
    r.style.transform = `translate(${
      startBarApp.x.toFixed(1) - 550
    }px,${posy}px) scale(0.25)`;
    this.props.hasMinimised(this.id);
  };

  focusWindow = () => {
    this.props.focus(this.id);
  };

  restoreWindow = () => {
    var r = document.querySelector("#" + this.id);
    this.setDefaultWindowDimenstion();
    // get previous position
    let posx = r.style.getPropertyValue("--window-transform-x");
    let posy = r.style.getPropertyValue("--window-transform-y");

    r.style.transform = `translate(${posx},${posy})`;
    setTimeout(() => {
      this.setState({ maximized: false });
    }, 300);
  };

  maximizeWindow = () => {
    this.props.playC();
    if (this.state.maximized) {
      this.restoreWindow();
    } else {
      this.focusWindow();
      var r = document.querySelector("#" + this.id);
      this.setWindowsPosition();
      r.style.transform = `translate(0px,-2pt)`;
      this.setState({ maximized: true, height: 97.4, width: 100 });
    }
  };

  closeWindow = () => {
    this.props.playC();

    this.setWindowsPosition();
    this.setState({ closed: true }, () => {
      setTimeout(() => {
        this.props.closed(this.id);
      }, 300);
    });
  };

  render() {
    return (
      <Draggable
        axis="both"
        handle=".window-top-bar"
        grid={[1, 1]}
        scale={1}
        disabled={this.state.maximized ? true : false}
        onStart={this.changeCursorToMove}
        onStop={this.changeCursorToDefault}
        //onDrag={this.focusWindow}
        allowAnyClick={false}
        defaultPosition={{ x: this.startX, y: this.startY }}
        bounds={{
          left: 0,
          top: 0,
          right: this.state.parentSize.width,
          bottom: this.state.parentSize.height,
        }}
        nodeRef={this.myRef} //to remove warning in console
      >
        <div
          ref={this.myRef} //to remove warning in console
          style={{
            width: `${this.state.width}%`,
            height: `${this.state.height}%`,
          }}
          className={
            "window-main " +
            (this.props.isFocused
              ? "window-main-focused "
              : "window-main-not-focused ") +
            " " +
            (this.state.closed ? "closed-window " : " ") +
            (this.props.minimized ? "minimized-window " : " ") +
            (this.state.maximized ? "maximized-window " : " ") +
            (this.props.dark && "window-main-dark ")
          }
          id={this.id}
        >
          <WindowTopBar
            cursorType={this.state.cursorType}
            heading={this.props.heading}
            title={this.props.title}
            icon={this.props.icon}
            dark={this.props.dark}
          />
          <WindowEditButtons
            minimize={this.minimizeWindow}
            maximize={this.maximizeWindow}
            isMaximised={this.state.maximized}
            resizable={this.props.resizable}
            close={this.closeWindow}
            id={this.id}
            dark={this.props.dark}
          />

          <WindowMainScreen
            id={this.id}
            changeBackgroundImage={this.props.changeBackgroundImage}
            bg_image_name={this.props.bg_image_name}
            screen={this.props.screen}
            title={this.props.title}
            dark={this.props.dark}
            playC={this.props.playC}
            playE={this.props.playE}
            toggleDarkMode={this.props.toggleDarkMode}
            audio={this.props.audio}
          />
        </div>
      </Draggable>
    );
  }
}

export default Window;

// Window's title bar
export function WindowTopBar(props) {
  return (
    <div
      className={"window-top-bar " + (props.dark && "window-top-bar-dark ")}
      style={{ cursor: props.cursorType }}
    >
      <div
        className={
          "window-top-bar-content " +
          (props.dark && "window-top-bar-content-dark ")
        }
      >
        <div
          className="top-bar-icon"
          style={{
            backgroundImage: `url(${props.icon})`,
            backgroundSize: "18px 18px",
          }}
        />
        {props.heading}
      </div>
    </div>
  );
}

// Window's Edit Buttons
export function WindowEditButtons(props) {
  return (
    <div className="window-edit-main">
      <button
        tabIndex="-1"
        style={{
          backgroundImage: props.dark
            ? `url(
            "./images/icon/window/window-minimize-symbolic-white.svg"
          )`
            : `url(
            "./images/icon/window/window-minimize-symbolic.svg"
          )`,
          cursor: `${props.id === "terminal" ? "not-allowed" : ""}`,
        }}
        id={`close-${props.id}`}
        disabled={props.id === "terminal" ? true : false}
        className={"nav-bar-btn " + (props.dark && "nav-bar-btn-dark ")}
        onClick={props.minimize}
      ></button>
      {props.isMaximised ? (
        <button
          tabIndex="-1"
          style={{
            backgroundImage: props.dark
              ? `url(
              "/images/icon/window/window-restore-symbolic-white.svg"
            )`
              : `url(
              "/images/icon/window/window-restore-symbolic.svg"
            )`,
            cursor: `${!props.resizable ? "not-allowed" : ""}`,
          }}
          id={`close-${props.id}`}
          className={"nav-bar-btn " + (props.dark && "nav-bar-btn-dark ")}
          disabled={!props.resizable}
          onClick={props.maximize}
        ></button>
      ) : (
        <button
          tabIndex="-1"
          style={{
            backgroundImage: props.dark
              ? `url(
              "/images/icon/window/window-maximize-symbolic-white.svg"
            )`
              : `url(
              "/images/icon/window/window-maximize-symbolic.svg"
            )`,
            cursor: `${!props.resizable ? "not-allowed" : ""}`,
          }}
          id={`close-${props.id}`}
          className={"nav-bar-btn " + (props.dark && "nav-bar-btn-dark ")}
          disabled={!props.resizable}
          onClick={props.maximize}
        ></button>
      )}
      <button
        tabIndex="-1"
        style={{
          backgroundImage: props.dark
            ? `url(
            "/images/icon/window/window-close-symbolic-white.svg"
          )`
            : `url(
            "/images/icon/window/window-close-symbolic.svg"
          )`,
        }}
        id={`close-${props.id}`}
        className={"nav-bar-btn " + (props.dark && "nav-bar-btn-dark ")}
        onClick={props.close}
      ></button>
    </div>
  );
}

// Window's Main Screen
export class WindowMainScreen extends Component {
  constructor() {
    super();
    this.state = {
      setDarkBg: false,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ setDarkBg: true });
    }, 3000);
  }
  render() {
    return (
      <div
        className={
          "window-main-screen " +
          (this.props.dark && "window-main-screen-dark ")
        }
      >
        <div
          className={`${
            this.props.id !== "aboutme" && this.props.id !== "projects"
              ? "window-main-screen-content-i " +
                (this.props.dark && "window-main-screen-content-i-dark ")
              : ""
          }`}
        >
          <div
            className={`${
              this.props.id !== "aboutme" && this.props.id !== "projects"
                ? "window-main-screen-content-ii " +
                  (this.props.dark && "window-main-screen-content-ii-dark ")
                : ""
            }`}
          >
            {this.props.id === "settings" && (
              <Settings
                changeBackgroundImage={this.props.changeBackgroundImage}
                bg_image_name={this.props.bg_image_name}
                playC={this.props.playC}
                dark={this.props.dark}
                toggleDarkMode={this.props.toggleDarkMode}
              />
            )}
            {this.props.id === "aboutme" &&
              displayAboutPrabin({
                dark: this.props.dark,
                playC: this.props.playC,
              })}
            {this.props.id === "message" &&
              displaySendMessage({
                dark: this.props.dark,
                playC: this.props.playC,
                playE: this.props.playE,
              })}
            {this.props.id === "projects" &&
              displayProjects({
                dark: this.props.dark,
                playC: this.props.playC,
              })}
            {this.props.id === "music" &&
              displayMusic({ dark: this.props.dark, audio: this.props.audio })}
            {this.props.id === "minesweeper" &&
              displayMineSweeper({
                dark: this.props.dark,
                playC: this.props.playC,
              })}
            {this.props.id === "terminal" && displayTerminal(this.props.dark)}
            {this.props.id === "explorer" && displayExplorer(this.props.dark)}
          </div>
        </div>
      </div>
    );
  }
}
