import React, { Component } from "react";
import "./computer.css";

export class Computer extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return <div className="computer-main">Computer Developement</div>;
  }
}

export default Computer;

export const displayComputer = () => {
  return <Computer> </Computer>;
};
