import React, { Component } from "react";
import "./windows_app.css";

class WindowsApp extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      name: "",
      icon: "",
      disabled: false,
    };
  }

  componentDidMount() {
    this.setState({
      id: this.props.id,
      name: this.props.name,
      icon: this.props.icon,
      disabled: this.props.disabled,
    });
  }

  openApp = () => {
    if (!this.props.disabled) {
      this.props.playd();
      setTimeout(() => {
        this.props.openApp(this.props.id);
      }, 300);
    } else {
      this.props.playe();
    }
  };

  render() {
    return (
      <div
        className="window-app"
        id={"app-" + this.state.id}
        onDoubleClick={this.openApp}
        tabIndex={0}
        style={{
          opacity: `${this.props.refresh ? "0" : "1"}`,
          cursor: `${this.props.disabled ? "not-allowed" : ""}`,
        }}
      >
        <img
          className="img-icon"
          src={this.state.icon}
          alt={"Windows " + this.state.name}
          height="38px"
          width="38px"
        />
        <div className="img-title">{this.state.name}</div>
      </div>
    );
  }
}

export default WindowsApp;
