import React from "react";
import "./music.css";
import useSound from "use-sound";
import useKeyboardShortcut from "use-keyboard-shortcut";

const Music = ({ dark, audio }) => {
  const soundUrl = "./images/sound/909-drums.mp3";

  const [play] = useSound(soundUrl, {
    sprite: {
      kick: [0, 350],
      hihat: [374, 160],
      snare: [666, 290],
      cowbell: [968, 200],
    },
  });

  useKeyboardShortcut(["1"], () => audio && play({ id: "kick" }));
  useKeyboardShortcut(["2"], () => audio && play({ id: "hihat" }));
  useKeyboardShortcut(["3"], () => audio && play({ id: "snare" }));
  useKeyboardShortcut(["4"], () => audio && play({ id: "cowbell" }));

  return (
    <div className={dark ? "default-main-dark " : "default-main "}>
      <div className="heading-music">Create your Own Music</div>
      <div className="music-button-wrapper">
        <div
          className={
            "music-button border-button-effect " +
            (dark && "music-button-dark border-button-effect-dark ")
          }
          aria-label="kick"
          onClick={() => audio && play({ id: "kick" })}
          style={{
            backgroundImage: `url( "./images/numbers/1.png"
            )`,
          }}
        ></div>
        <div
          className={
            "music-button border-button-effect " +
            (dark && "music-button-dark border-button-effect-dark ")
          }
          aria-label="hihat"
          onClick={() => audio && play({ id: "hihat" })}
          style={{
            backgroundImage: `url( "./images/numbers/2.png"
            )`,
          }}
        ></div>
        <div
          className={
            "music-button border-button-effect " +
            (dark && "music-button-dark border-button-effect-dark ")
          }
          aria-label="snare"
          onClick={() => audio && play({ id: "snare" })}
          style={{
            backgroundImage: `url( "./images/numbers/3.png"
            )`,
          }}
        ></div>
        <div
          className={
            "music-button border-button-effect " +
            (dark && "music-button-dark border-button-effect-dark ")
          }
          aria-label="cowbell"
          onClick={() => audio && play({ id: "cowbell" })}
          style={{
            backgroundImage: `url( "./images/numbers/4.png"
            )`,
          }}
        ></div>
      </div>
      <div style={{ fontSize: "16px", marginTop: "16px", color: "gray" }}>
        (Press Key 1 2 3 4 for shortcut)
      </div>
    </div>
  );
};

export default Music;

export const displayMusic = ({ dark, audio }) => {
  return <Music dark={dark} audio={audio}></Music>;
};
