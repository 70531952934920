import React, { Component } from "react";
import "./default.css";

export class Default extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return <div className="default-main">Under Developement</div>;
  }
}

export default Default;

export const displayDefault = () => {
  return <Default> </Default>;
};
