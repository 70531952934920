import React from "react";
import "./marker.css";

const Marker = () => {
  return (
    <div className="marker-wrapper">
      <div className="created-div">Created By</div>
      <div className="creator-div">&#169; Prabin Shrestha</div>
    </div>
  );
};

export default Marker;
