import React, { Component } from "react";
import $ from "jquery";
import "./terminal.css";

export class Terminal extends Component {
  constructor() {
    super();
    this.cursor = "";
    this.terminal_rows = 1;
    this.current_directory = "pravinewa";
    this.curr_dir_name = "root";
    this.prev_commands = [];
    this.commands_index = 0;
    this.child_directories = {
      root: [
        "books",
        "projects",
        "personal-documents",
        "skills",
        "languages",
        "hobbies",
      ],
      books: [
        "Deathly Hallows.pdf",
        "The Hobbit.pdf",
        "Lord of the Rings.pdf",
        "To Kill a Mockingbird.pdf",
        "Rita Hayworth and Shawshank Redemption.pdf",
      ],
      projects: [
        "MyPortfolio",
        "Entercoding",
        "FoodHUT",
        "RecipeApp",
        "ARSystem",
        "ShopRegistrar",
      ],
      skills: [
        "FullStack development",
        "React.js",
        "Spring Boot",
        "Flutter",
        "NodeJs",
        "SQL",
        "Firebase",
        "MongoDB",
      ],
      languages: ["Javascript", "Java", "Dart"],
      hobbies: [
        "Sleeping",
        "Eating",
        "Coding",
        "Playing COD",
        "Watching Series",
      ],
      MyPortfolio: [
        ".gitignore",
        "node_modules",
        "public",
        "src",
        "package-lock.json",
        "package.json",
        "README.md",
      ],
      Entercoding: [
        ".gitignore",
        "node_modules",
        "public",
        "src",
        "package-lock.json",
        "package.json",
        "README.md",
      ],
      FoodHUT: [
        ".gitignore",
        "node_modules",
        "public",
        "src",
        "package-lock.json",
        "package.json",
        "README.md",
      ],
      RecipeApp: [
        ".gitignore",
        ".mvn",
        "public",
        "src",
        "mvnw",
        "mvnw.cmd",
        "pom.xml",
        "README.md",
      ],
      ARSystem: [
        ".gitignore",
        "node_modules",
        "public",
        "src",
        "package-lock.json",
        "package.json",
        "README.md",
      ],
      ShopRegistrar: [
        ".gitignore",
        "android",
        "assets",
        "ios",
        "lib",
        "pubspec.lock",
        "pubspec.yaml",
        "README.md",
      ],
    };
    this.state = {
      terminal: [],
    };
  }

  componentDidMount() {
    this.restartTerminal();
  }

  componentDidUpdate() {
    clearInterval(this.cursor);
    this.startCursor(this.terminal_rows - 2);
  }

  componentWillUnmount() {
    clearInterval(this.cursor);
  }

  restartTerminal = () => {
    clearInterval(this.cursor);
    $("#terminal-body").empty();
    this.appendTerminalRow();
  };

  appendTerminalRow = () => {
    let terminal = this.state.terminal;
    terminal.push(this.terminalRow(this.terminal_rows));
    this.setState({ terminal });
    this.terminal_rows += 2;
  };

  terminalRow = (id) => {
    return (
      <React.Fragment key={id}>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "20px",
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ color: "green" }}>C:\WINDOWS\SYSTEM32</div>
            <div
              style={{
                color: "green",
                marginRight: "1px",
                marginLeft: "1px",
                fontWeight: "500",
              }}
            >
              \
            </div>
            <div style={{ color: "green" }}>{this.current_directory}</div>
            <div
              style={{
                color: "green",
                marginRight: "4px",
                marginLeft: "1px",
                fontWeight: "500",
              }}
            >
              &gt;
            </div>
          </div>
          <div id="cmd" className="terminal-cmd">
            <span
              id={`show-${id}`}
              style={{
                float: "left",
                whiteSpace: "pre",
                paddingBottom: "4px",
                opacity: "100",
                fontWeight: "400",
                letterSpacing: "0.8px",
              }}
            ></span>
            <div
              id={`cursor-${id}`}
              style={{
                float: "left",
                marginTop: "4px",
                height: "14px",
                width: "7px",
                backgroundColor: "green",
              }}
            ></div>
            <input
              id={`terminal-input-${id}`}
              data-row-id={id}
              onKeyDown={this.checkKey}
              onBlur={this.unFocusCursor}
              className="terminal-input"
              spellCheck={false}
              autoFocus={true}
              autoComplete="off"
              type="text"
              maxLength="25"
            />
          </div>
        </div>
        <div
          id={`row-result-${id}`}
          style={{ marginTop: "8px", marginBottom: "8px", fontWeight: "500" }}
        ></div>
      </React.Fragment>
    );
  };

  unFocusCursor = (e) => {
    this.stopCursor($(e.target).data("row-id"));
  };

  focusCursor = (e) => {
    clearInterval(this.cursor);
    this.startCursor($(e.target).data("row-id"));
  };

  startCursor = (id) => {
    clearInterval(this.cursor);
    $(`input#terminal-input-${id}`).trigger("focus");
    // On input change, set current text in span
    $(`input#terminal-input-${id}`).on("input", function () {
      $(`#cmd span#show-${id}`).text($(this).val());
    });

    this.cursor = window.setInterval(function () {
      if ($(`#cursor-${id}`).css("visibility") === "visible") {
        $(`#cursor-${id}`).css({ visibility: "hidden" });
      } else {
        $(`#cursor-${id}`).css({ visibility: "visible" });
      }
    }, 500);
  };

  stopCursor = (id) => {
    clearInterval(this.cursor);
    $(`#cursor-${id}`).css({ visibility: "visible" });
  };

  removeCursor = (id) => {
    this.stopCursor(id);
    $(`#cursor-${id}`).css({ display: "none" });
  };

  clearInput = (id) => {
    $(`input#terminal-input-${id}`).trigger("blur");
  };

  checkKey = (e) => {
    if (e.key === "Enter") {
      let terminal_row_id = $(e.target).data("row-id");
      let command = $(`input#terminal-input-${terminal_row_id}`).val().trim();
      if (command.length !== 0) {
        this.removeCursor(terminal_row_id);
        this.handleCommands(command, terminal_row_id);
      } else return;
      // push to history
      this.prev_commands.push(command);
      this.commands_index = this.prev_commands.length;

      this.clearInput(terminal_row_id);
    } else if (e.key === "ArrowUp") {
      let prev_command;

      if (this.commands_index <= 0) return;
      else prev_command = this.prev_commands[this.commands_index - 1];
      this.commands_index--;

      let terminal_row_id = $(e.target).data("row-id");

      $(`#show-${terminal_row_id}`).text(prev_command);
      $(`input#terminal-input-${terminal_row_id}`).val(prev_command);
    } else if (e.key === "ArrowDown") {
      let prev_command;
      if (this.commands_index >= this.prev_commands.length) return;
      this.commands_index++;
      if (this.commands_index <= 0) this.commands_index = 0;

      if (this.commands_index === this.prev_commands.length) {
        prev_command = "";
      } else {
        prev_command = this.prev_commands[this.commands_index];
      }

      let terminal_row_id = $(e.target).data("row-id");

      $(`#show-${terminal_row_id}`).text(prev_command);
      $(`input#terminal-input-${terminal_row_id}`).val(prev_command);
    } else if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
      e.preventDefault();
    }
    let input = document.getElementById(
      "terminal-input-" + $(e.target).data("row-id")
    );
    if (input.setSelectionRange) {
      input.focus();
      input.setSelectionRange(25, 25);
    }
  };

  childDirectories = (parent) => {
    let childs_dir = [];
    childs_dir.push(`<div class="child-dir" >`);
    this.child_directories[parent].forEach((file) => {
      childs_dir.push(`<span class="child-dir-item" >'${file}'&emsp;</span>`);
    });
    childs_dir.push(`</div>`);
    return childs_dir;
  };

  handleCommands = (command, rowId) => {
    let words = command.split(" ").filter(Boolean);
    let main = words[0];
    words.shift();
    let result = "";
    let rest = words.join(" ");
    rest = rest.trim();
    switch (main) {
      case "cd":
        if (words.length === 0 || rest === "") {
          break;
        }
        if (rest === ".." || rest === "../") {
          this.current_directory = "pravinewa";
          this.curr_dir_name = "root";
          break;
        }

        if (
          this.child_directories["root"].includes(rest) ||
          this.child_directories["projects"].includes(rest)
        ) {
          if (rest === "personal-documents") {
            result = `win95 \\${this.curr_dir_name} : Am I a joke to you 😏`;
            break;
          }
          this.current_directory += "\\" + rest;
          this.curr_dir_name = rest;
        } else {
          if (this.child_directories[this.curr_dir_name].includes(rest)) {
            result = "Nothing to show from here!";
          } else {
            result = `win95: cd: ${words}: No such file or directory`;
          }
        }
        break;
      case "ls":
        let target = words[0];
        if (target === "" || target === undefined || target === null)
          target = this.curr_dir_name;

        if (target in this.child_directories) {
          result = this.childDirectories(target).join("");
        } else {
          result = `ls: cannot access '${words}': No such file or directory                    `;
        }
        break;
      case "echo":
        result = this.xss(words.join(" "));
        break;
      case "clear":
        this.restartTerminal();
        return;
      case "sudo":
        result = `<img src='./images/fun/i_am_root.png' width="25%"/>`;
        break;
      case "apt-get":
        result = `<img src='./images/fun/hacker.png' width="25%"/>`;
        break;
      case "creator":
        result = `Prabin Shrestha`;
        break;
      case "github":
        result = `https://github.com/whopravinewa`;
        break;
      case "email":
        result = `whopravinewa@gmail.com`;
        break;
      default:
        result =
          "Command '" +
          main +
          "' not found, or not yet implemented.<br>Available Commands: [ cd, clear, creator, echo, email, github, ls ]";
    }
    document.getElementById(`row-result-${rowId}`).innerHTML = result;
    this.appendTerminalRow();
  };

  xss(str) {
    if (!str) return;
    return str
      .split("")
      .map((char) => {
        switch (char) {
          case "&":
            return "&amp";
          case "<":
            return "&lt";
          case ">":
            return "&gt";
          case '"':
            return "&quot";
          case "'":
            return "&#x27";
          case "/":
            return "&#x2F";
          default:
            return char;
        }
      })
      .join("");
  }

  render() {
    return (
      <div
        className={
          "terminal-main " + (this.props.dark && "terminal-main-dark ")
        }
      >
        <div style={{ padding: "10px" }} id="terminal-body">
          {this.state.terminal}
        </div>
      </div>
    );
  }
}

export default Terminal;

export const displayTerminal = (dark) => {
  return <Terminal dark={dark}> </Terminal>;
};
