import React from "react";
import "./background_image.css";
const BackGroundImage = ({ bgImgName }) => {
  const bg_img = {
    "wall-1": "./images/wallpapers/default-green.png",
    "wall-2": "./images/wallpapers/default-blue-with-logo.png",
    "wall-3": "./images/wallpapers/sky-with-logo.jpg",
    "wall-4": "./images/wallpapers/blue-screen-of-death.png",
    "wall-5": "./images/wallpapers/green-with-logo.jpg",
    "wall-6": "./images/wallpapers/sky-with-logo-2.jpg",
    "wall-7": "./images/wallpapers/stonehenge.jpg",
    "wall-8": "./images/wallpapers/window-wallpaper.jpg",
    "wall-9": "./images/wallpapers/xp-wallpaper.jpg",
  };

  return (
    <div
      className="bg-window-img"
      style={{
        backgroundImage: `url(${bg_img[bgImgName]})`,
      }}
    ></div>
  );
};

export default BackGroundImage;
