import React, { Component } from "react";
import BackGroundImage from "../../util/background-image/background_image";
import apps from "../../config/app_config";
import WindowsApp from "../windows_app/windows_app";
import Window from "../window/window";
import "./desktop.css";
import StartMenu from "../start-menu/start_menu";
import StartBar from "../start_bar/start_bar";
import Marker from "../../util/Marker/marker";
import DesktopMenu from "../../context_menus/desktop_menu";
import $ from "jquery";
import GoogleAd from "../../ads/ads";

class Desktop extends Component {
  constructor() {
    super();
    this.app_stack = [];
    this.allWindowClosed = false;
    this.state = {
      audio: true,
      dark: false,
      menu: false,
      focused_windows: {},
      closed_windows: {},
      disabled_apps: {},
      minimized_windows: {},
      menu_apps: [],
      desktop_apps: [],
      context_menus: {
        desktop: false,
      },
      refresh: false,
    };
    this.click = new Audio("./images/sound/click.mp3");
    this.dclick = new Audio("./images/sound/dclick.mp3");
    this.eclick = new Audio("./images/sound/error.mp3");
  }

  componentDidMount() {
    this.fetchAppsData();
    this.setContextListeners();
    this.getLocalData();
  }

  getLocalData = () => {
    let darkMode = localStorage.getItem("dark-mode");
    if (darkMode !== null && darkMode !== undefined) {
      if (darkMode === "true") {
        this.setState({
          dark: true,
        });
      } else {
        this.setState({
          dark: false,
        });
      }
    }

    let audio = localStorage.getItem("audio");
    if (audio !== null && audio !== undefined) {
      if (audio === "true") {
        this.setState({
          audio: true,
        });
      } else {
        this.setState({
          audio: false,
        });
      }
    }
  };

  componentWillUnmount() {
    this.removeContextListeners();
  }

  fetchAppsData = () => {
    let focused_windows = {},
      disabled_apps = {},
      closed_windows = {},
      minimized_windows = {};

    let desktop_apps = [];
    let menu_apps = [];
    apps.forEach((app) => {
      focused_windows = {
        ...focused_windows,
        [app.id]: false,
      };
      disabled_apps = {
        ...disabled_apps,
        [app.id]: app.disabled,
      };
      closed_windows = {
        ...closed_windows,
        [app.id]: true,
      };
      minimized_windows = {
        ...minimized_windows,
        [app.id]: false,
      };
      if (app.menu_shortcut) menu_apps.push(app.id);
      if (app.desktop_shortcut) desktop_apps.push(app.id);
    });
    this.setState({
      focused_windows,
      closed_windows,
      disabled_apps,
      menu_apps,
      minimized_windows,
      desktop_apps,
    });
  };

  toggleDarkMode = (val) => {
    if (val) {
      this.setState({
        dark: true,
      });
    } else {
      this.setState({
        dark: false,
      });
    }
    localStorage.setItem("dark-mode", val);
  };

  setContextListeners = () => {
    document.addEventListener("contextmenu", this.checkContextMenu);
    document.addEventListener("click", this.hideAllContextMenu);
  };

  removeContextListeners = () => {
    document.removeEventListener("contextmenu", this.checkContextMenu);
    document.removeEventListener("click", this.hideAllContextMenu);
  };

  checkContextMenu = (e) => {
    e.preventDefault();
    this.hideAllContextMenu();
    if (e.target.dataset.context === "desktop-area") {
      this.showContextMenu(e);
    }
  };

  showContextMenu = (e) => {
    let { posx, posy } = this.getMenuPosition(e);
    let contextMenu = document.getElementById("desktop-menu");

    if (posx + $(contextMenu).width() > window.innerWidth)
      posx -= $(contextMenu).width();
    if (posy + $(contextMenu).height() > window.innerHeight)
      posy -= $(contextMenu).height();

    posx = posx.toString() + "px";
    posy = posy.toString() + "px";

    contextMenu.style.left = posx;
    contextMenu.style.top = posy;

    this.setState({ context_menus: { desktop: true } });
  };

  hideAllContextMenu = () => {
    this.setState({ context_menus: { desktop: false } });
  };

  getMenuPosition = (e) => {
    var posx = 0;
    var posy = 0;

    if (!e) e = window.event;

    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx =
        e.clientX +
        document.body.scrollLeft +
        document.documentElement.scrollLeft;
      posy =
        e.clientY +
        document.body.scrollTop +
        document.documentElement.scrollTop;
    }
    return {
      posx,
      posy,
    };
  };

  playC = () => {
    this.state.audio && this.click.play();
  };
  playD = () => {
    this.state.audio && this.dclick.play();
  };
  playE = () => {
    this.state.audio && this.eclick.play();
  };
  renderDesktopApps = () => {
    if (Object.keys(this.state.closed_windows).length === 0) return;
    let appsJsx = [];
    apps.forEach((app, index) => {
      if (this.state.desktop_apps.includes(app.id)) {
        const props = {
          name: app.title,
          id: app.id,
          icon: app.icon,
          disabled: app.disabled,
          openApp: this.openApp,
          refresh: this.state.refresh,
          playd: this.playD,
          playe: this.playE,
          dark: this.state.dark,
        };

        appsJsx.push(<WindowsApp key={index} {...props} />);
      }
    });
    return appsJsx;
  };

  renderWindows = () => {
    let windowsJsx = [];
    apps.forEach((app, index) => {
      if (this.state.closed_windows[app.id] === false) {
        const props = {
          title: app.title,
          id: app.id,
          heading: app.heading,
          icon: app.icon,
          screen: app.screen,
          closed: this.closeApp,
          focus: this.focus,
          resizable: app.resizable,
          playC: this.playC,
          playE: this.playE,
          isFocused: this.state.focused_windows[app.id],
          hasMinimised: this.hasMinimised,
          minimized: this.state.minimized_windows[app.id],
          changeBackgroundImage: this.props.changeBackgroundImage,
          bg_image_name: this.props.bg_image_name,
          dark: this.state.dark,
          toggleDarkMode: this.toggleDarkMode,
          audio: this.state.audio,
        };

        windowsJsx.push(<Window key={index} {...props} />);
      }
    });
    return windowsJsx;
  };

  openApp = (objId) => {
    if (this.state.disabled_apps[objId]) return;

    if (this.state.minimized_windows[objId]) {
      // focus this app's window
      this.focus(objId);

      // set window's last position
      var r = document.querySelector("#" + objId);
      r.style.transform = `translate(${r.style.getPropertyValue(
        "--window-transform-x"
      )},${r.style.getPropertyValue("--window-transform-y")}) scale(1)`;

      // tell childs that his app has been not minimised
      let minimized_windows = this.state.minimized_windows;
      minimized_windows[objId] = false;
      this.setState({ minimized_windows: minimized_windows });
      return;
    }

    if (this.app_stack.includes(objId)) this.focus(objId);
    else {
      let closed_windows = this.state.closed_windows;
      closed_windows[objId] = false;
      this.setState({ closed_windows }, this.focus(objId));
      this.app_stack.push(objId);
    }
  };

  closeApp = (objId) => {
    this.app_stack.splice(this.app_stack.indexOf(objId), 1);
    this.giveFocusToLastApp();
    let closed_windows = this.state.closed_windows;

    closed_windows[objId] = true;

    this.setState({ closed_windows });
  };

  hasMinimised = (objId) => {
    let minimized_windows = this.state.minimized_windows;
    var focused_windows = this.state.focused_windows;

    // remove focus and minimise this window
    minimized_windows[objId] = true;
    focused_windows[objId] = false;
    this.setState({ minimized_windows, focused_windows });

    this.giveFocusToLastApp();
  };

  giveFocusToLastApp = () => {
    // if there is atleast one app opened, give it focus
    if (!this.checkAllMinimised()) {
      for (const index in this.app_stack) {
        if (!this.state.minimized_windows[this.app_stack[index]]) {
          this.focus(this.app_stack[index]);
          break;
        }
      }
    }
  };

  checkAllMinimised = () => {
    let result = true;
    for (const key in this.state.minimized_windows) {
      if (!this.state.closed_windows[key]) {
        // if app is opened
        result = result & this.state.minimized_windows[key];
      }
    }
    return result;
  };

  focus = (objId) => {
    var focused_windows = this.state.focused_windows;
    focused_windows[objId] = true;
    for (let key in focused_windows) {
      if (focused_windows.hasOwnProperty(key)) {
        if (key !== objId) {
          focused_windows[key] = false;
        }
      }
    }
    this.setState({ focused_windows });
  };

  showMenu = () => {
    this.setState({
      menu: !this.state.menu,
    });
  };

  refresh = () => {
    this.setState({
      refresh: true,
    });
    setTimeout(() => {
      this.setState({
        refresh: false,
      });
    }, 80);
  };

  toggleAudio = () => {
    let val = !this.state.audio;
    this.setState({
      audio: val,
    });
    localStorage.setItem("audio", val);
  };

  render() {
    return (
      <div className="desktop-main">
        <div className="window-area" data-context="desktop-area">
          {this.renderWindows()}
        </div>
        <BackGroundImage bgImgName={this.props.bg_image_name} />
        <div className="ads-area-desktop">
          <GoogleAd slot="989038934" />
        </div>
        <Marker />
        {this.renderDesktopApps()}
        {this.state.menu && (
          <StartMenu
            apps={apps}
            menu_apps={this.state.menu_apps}
            disabled_apps={this.state.disabled_apps}
            playC={this.playC}
            playE={this.playE}
            openApp={this.openApp}
            menu={this.state.menu}
            showMenu={this.showMenu}
            restart={this.props.restart}
            dark={this.state.dark}
          />
        )}
        <StartBar
          menu={this.state.menu}
          apps={apps}
          openApp={this.openApp}
          playC={this.playC}
          hasMinimised={this.hasMinimised}
          minimized={this.state.minimized_windows}
          app_stack={this.app_stack}
          showMenu={this.showMenu}
          focus={this.focus}
          focused_windows={this.state.focused_windows}
          dark={this.state.dark}
          audio={this.state.audio}
          toggleAudio={this.toggleAudio}
        />
        <DesktopMenu
          active={this.state.context_menus.desktop}
          openApp={this.openApp}
          playC={this.playC}
          refresh={this.refresh}
          dark={this.state.dark}
        />
      </div>
    );
  }
}
export default Desktop;
